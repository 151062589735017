<template>
	<div class="ddzy">
		<div class="tit">
			{{i18n('179')}}
		</div>
		<div class="list">
			<div class="item">
				<div class="left">
					{{i18n('180')}}：
				</div>
				<div class="right">
					{{orderData.order.order_number}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('181')}}：
				</div>
				<div class="right" v-if='orderData.order.pay_method==1'>{{i18n('339')}}</div>
				<div class="right" v-if='orderData.order.pay_method==2'>{{i18n('340')}}</div>
				<div class="right" v-if='orderData.order.pay_method==3'>{{i18n('341')}}</div>
				<div class="right" v-if='orderData.order.pay_method==4'>{{i18n('342')}}</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('167')}}：
				</div>
				<div class="right" style="color: #F23725;" v-if="orderData.order.distribution_fee!=0">
					<span>₱</span>{{orderData.order.distribution_fee}}
				</div>
				<div class="right" style="color: #F23725;" v-else>
					{{i18n('182')}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('168')}}：
				</div>
				<div class="right" style="color: #F23725;">
					<span>₱</span>{{orderData.order.preferential_amount}}
				</div>
			</div>
		</div>
		<div class="list" style="border-bottom: none;">
			<div class="item">
				<div class="left">
					{{i18n('169')}}：
				</div>
				<div class="right" style="color: #F23725;">
					<span>₱</span>{{orderData.order.total_price}}
				</div>
			</div>
		</div>
		<div class="but" v-if="status==1" @click="$router.push('/pay?set_id='+orderData.set.id+'&pay_price='+orderData.order.total_price)">
			{{i18n('183')}}
		</div>
		<div class="but" v-if="status==4" @click="sh">
			{{i18n('184')}}
		</div>
	</div>
</template>

<script>
	export default {
		props:['orderData','status'],
		name: "ddzy",
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed: {
		},
		methods: {
			sh(){
				this.$message.success(this.i18n('185'))
			}
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.but{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		text-align: center;
		line-height: 44px;
		cursor: pointer;
	}
	.list{
		width: 241px;
		margin: 0 auto;
		padding: 12px 0;
		border-bottom: 1px solid #e8e8e8;
	}
	.item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
	}
	.item .left{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.item .right{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #1D2129;
		text-align: right;
		line-height: 28px;
		font-weight: 400;
	}
	.item .right span{
		font-size: 12px;
	}
	.ddzy{
		width: 290px;
		background: #FFFFFF;
		border-radius: 9px;
		padding-bottom: 20px;
		margin: 0 auto;
	}
	.tit{
		width: 100%;
		padding-left: 24px;
		height: 48px;
		line-height: 48px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		border-bottom: 1px solid #56AB7B;
		box-sizing: border-box;
	}
</style>