<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close" class="seleAddressWap">
		<div slot="title">
			<div class="titleLeft">
				<div class="">
					{{i18n('99')}}
				</div>
			</div>
			<div class="titleRight" @click="close">
				<div class="">
					<img src="@/assets/close.png" alt="">
				</div>
			</div>
		</div>
		<div class="bg">
			<div :class="index==bgDataIndex?'ac':''" v-for="(item,index) in bgData" :key='index' @click="bgDataIndexChange(index)">
				{{item.genre==0?i18n('100'):i18n('101')}}
			</div>
		</div>
		<div class="spList" v-if="bgData.length>0 && bgData[bgDataIndex].spData[0].base">
			<div class="item" v-for="(item,index) in bgData[bgDataIndex].spData" :key="index">
				<img :src="item.base.cover" alt="">
				<div class="over">
					{{lang=='zh'?item.base.zh_name:lang=='en'?item.base.en_name:item.base.vi_name}}
				</div>
			</div>
		</div>
		<div class="timeList" v-if="bgData.length>0">
			<div class="left">
				<div :class="index==leftData.index?'item itemAc':'item'" v-for="(item,index) in leftData.data"
					:key="index" @click="seleLeft(index)">
					<span>{{item.label}}</span>{{item.labelTwo}}
				</div>
			</div>
			
			<div class="right">
				<div :class="listData.index==-1?'item itemAc':'item'" @click="seleRight(-1)"
				v-if="bgData[bgDataIndex].genre==0 && leftData.index==0 && leftData.data.length!=2 && hours>=storehouseStartTime">
					{{i18n('102')}}
				</div>
				<div :class="index==listData.index?'item itemAc':'item'" v-for="(item,index) in listData.data"
				:key="index" @click="seleRight(index)">
					{{item.label}}
				</div>
			</div>
		</div>
		<div class="but">
			<div class="qx" v-if="bgDataIndex==0" @click="close">
				{{i18n('55')}}
			</div>
			<div class="xt" v-else @click="ydyz">
				{{i18n('103')}}
			</div>
			<div class="qd" @click="confirm">
				{{i18n('56')}}
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: ['ydData', 'jsData'],
		name: "seleTime",
		data() {
			return {
				show:false,
				bgData: [
				
				],
				hours:7,
				storehouseStartTime:7,
				bgDataIndex: 0,
				timeType: 2,
				leftData: {
					index: 0,
					data: []
				},
				rightData: [],
				listData: {
					index: -2,
					data: []
				},
				storehouse: false,
				delivery_modeint: 0,
				timeData: [{
						value: '00:00:00',
						label: '0:00 am',
					}, {
						value: '00:30:00',
						label: '0:30 am',
					}, {
						value: '01:00:00',
						label: '1:00 am',
					}, {
						value: '01:30:00',
						label: '1:30 am',
					}, {
						value: '02:00:00',
						label: '2:00 am',
					}, {
						value: '02:30:00',
						label: '2:30 am',
					}, {
						value: '03:00:00',
						label: '3:00 am',
					}, {
						value: '03:30:00',
						label: '3:30 am',
					}, {
						value: '04:00:00',
						label: '4:00 am',
					}, {
						value: '04:30:00',
						label: '4:30 am',
					}, {
						value: '05:00:00',
						label: '5:00 am',
					}, {
						value: '05:30:00',
						label: '5:30 am',
					}, {
						value: '06:00:00',
						label: '6:00 am',
					}, {
						value: '06:30:00',
						label: '6:30 am',
					}, {
						value: '07:00:00',
						label: '7:00 am',
					},
					{
						value: '07:30:00',
						label: '7:30 am',
					},
					{
						value: '08:00:00',
						label: '8:00 am',
					},
					{
						value: '08:30:00',
						label: '8:30 am',
					},
					{
						value: '09:00:00',
						label: '9:00 am',
					},
					{
						value: '09:30:00',
						label: '9:30 am',
					},
					{
						value: '10:00:00',
						label: '10:00 am',
					},
					{
						value: '10:30:00',
						label: '10:30 am',
					},
					{
						value: '11:00:00',
						label: '11:00 am',
					},
					{
						value: '11:30:00',
						label: '11:30 am',
					},
					{
						value: '12:00:00',
						label: '12:00 pm',
					},
					{
						value: '12:30:00',
						label: '12:30 pm',
					},
					{
						value: '13:00:00',
						label: '1:00 pm',
					},
					{
						value: '13:30:00',
						label: '1:30 pm',
					},
					{
						value: '14:00:00',
						label: '2:00 pm',
					},
					{
						value: '14:30:00',
						label: '2:30 pm',
					},
					{
						value: '15:00:00',
						label: '3:00 pm',
					},
					{
						value: '15:30:00',
						label: '3:30 pm',
					},
					{
						value: '16:00:00',
						label: '4:00 pm',
					},
					{
						value: '16:30:00',
						label: '4:30 pm',
					},
					{
						value: '17:00:00',
						label: '5:00 pm',
					},
					{
						value: '17:30:00',
						label: '5:30 pm',
					},
					{
						value: '18:00:00',
						label: '6:00 pm',
					},
					{
						value: '18:30:00',
						label: '6:30 pm',
					},
					{
						value: '19:00:00',
						label: '7:00 pm',
					},
					{
						value: '19:30:00',
						label: '7:30 pm',
					},
					{
						value: '20:00:00',
						label: '8:00 pm',
					},
					{
						value: '20:30:00',
						label: '8:30 pm',
					},
					{
						value: '21:00:00',
						label: '9:00 pm',
					},
					{
						value: '21:30:00',
						label: '9:30 pm',
					},
					{
						value: '22:00:00',
						label: '10:00 pm',
					},
					{
						value: '22:30:00',
						label: '10:30 pm',
					},
					{
						value: '23:00:00',
						label: '11:00 pm',
					},
					{
						value: '23:30:00',
						label: '11:30 pm',
					},
					{
						value: '23:59:59',
						label: '12:00 am',
					}
				],
				
			}
		},
		computed:{
			lang(){
				return this.$store.state.i18n.lang
			}
		},
		methods: {
			confirm() {
				let delivery_timeValue = true
				this.bgData.map((item, index) => {
					if (item.rightIndex < -1) {
						if (item.genre == 0) {
							if (this.bgDataIndex == index) {
								this.$message.error(this.i18n('104'))
							} else {
								if (this.bgData[0].rightIndex >= -1) {
									console.log(this.bgData[0].rightIndex)
									this.bgDataIndexChange(index)
								}
							}
							delivery_timeValue = false
						} else {
							if (this.bgDataIndex == index) {
								this.$message.error(this.i18n('105'))
							} else {
								if (this.bgData[1].rightIndex >= -1) {
									this.bgDataIndexChange(index)
								}
							}
							delivery_timeValue = false
						}
					}
				})
				if (delivery_timeValue) {
					this.$emit("timeChange", this.bgData)
					this.close()
				}
			},
			//与预定一样
			ydyz() {
				if (this.bgData[0].delivery_time != 0) {
					this.seleLeft(this.bgData[0].leftIndex + 1)
					this.seleRight(this.bgData[0].rightIndex + 6)
			
					this.confirm()
			
				}
			},
			//初始化
			init() {
				if (this.bgData[this.bgDataIndex].genre == 0) {
					this.delivery_modeint = 1
				} else {
					this.delivery_modeint = 2
				}
				this.initLeftData() //初始化 左边 日期 数据
				this.initRightData() //初始化 右边 时间 数据
			},
			//初始化 左边 日期 数据
			initLeftData() {
				let {
					myDate,
					timeType
				} = this.getNewTime()
				let data = [{
						label: this.i18n('106'),
						value: '2021-8-31'
					},
					{
						label: this.i18n('107'),
						value: '2021-9-1'
					}
				]
				if (this.delivery_modeint == 2 || timeType == 3) {
					//明天和后天
			
					data[0].label = this.i18n('107')
					myDate.setDate(myDate.getDate() + 1)
					data[0].labelTwo = this.getDate(myDate, true)
					data[0].value = this.getDate(myDate)
			
					data[1].label =this.i18n('108')
					myDate.setDate(myDate.getDate() + 1)
					data[1].labelTwo = this.getDate(myDate, true)
					data[1].value = this.getDate(myDate)
				} else {
					//今天和明天还有后天
			
					data[0].label = this.i18n('106')
					data[0].labelTwo = this.getDate(myDate, true)
					data[0].value = this.getDate(myDate)
			
					data[1].label = this.i18n('107')
					myDate.setDate(myDate.getDate() + 1)
					data[1].labelTwo = this.getDate(myDate, true)
					data[1].value = this.getDate(myDate)
			
					data[2] = {
						label: this.i18n('108'),
						value: '2021-9-1'
					}
					myDate.setDate(myDate.getDate() + 1)
					data[2].labelTwo = this.getDate(myDate, true)
					data[2].value = this.getDate(myDate)
				}
				this.leftData.data = data
				this.bgData.map((item) => {
					if (this.delivery_modeint == 1) {
						if (item.genre == 0) {
							this.leftData.index = item.leftIndex
						}
					} else {
						if (item.genre == 1) {
							this.leftData.index = item.leftIndex
						}
					}
				})
			},
			//初始化 右边 时间 数据
			initRightData() {
				let {
					hours,
					minutes,
					timeType
				} = this.getNewTime()
				this.hours=parseInt(hours)
				let storehouseStartTime = parseInt(this.storehouse.start_time) //仓库开始营业时间
				let storehouseEndTime = parseInt(this.storehouse.end_time) //仓库结束营业时间
				this.storehouseStartTime=storehouseStartTime
				this.storehouseEndTime=storehouseEndTime
				let startIndex = 0
				let endIndex = 0
			
				this.timeData.map((item, index) => {
					let time = parseInt(item.value)
					if (time == storehouseStartTime) {
						startIndex = index - 1
					}
				})
				this.timeData.splice(0, startIndex)
				this.timeData.map((item, index) => {
					let time = parseInt(item.value)
					if (storehouseEndTime == 24) {
						endIndex = this.timeData.length
					} else if (time == storehouseEndTime) {
						endIndex = index
					}
				})
				this.timeData = this.timeData.splice(0, endIndex)
				let data = [JSON.parse(JSON.stringify(this.timeData)), JSON.parse(JSON.stringify(this.timeData))]
				if(this.delivery_modeint == 1){
					data.push(JSON.parse(JSON.stringify(this.timeData)))
				}
				if (timeType == 2 && this.delivery_modeint == 1) {
					let ci = true
					this.timeData.map((item, index) => {
						let time = parseInt(item.value)
						if (time == hours && ci) {
							ci = false
							if (minutes < 30) {
								data[0].splice(0, index + 3)
							} else {
								data[0].splice(0, index + 4)
							}
						}
					})
				} else if (this.delivery_modeint == 2) {
					let ci = true
					this.timeData.map((item, index) => {
						let time = parseInt(item.value)
						if (time == 10 && ci) {
							ci = false
							data[0].splice(0, index)
							data[1].splice(0, index)
						}
					})
				}
				this.rightData = data
				this.listData.data = data[this.leftData.index]
				this.bgData.map((item) => {
					if (this.delivery_modeint == 1) {
						if (item.genre == 0) {
							this.listData.index = item.rightIndex
						}
					} else {
						if (item.genre == 1) {
							this.listData.index = item.rightIndex
						}
					}
				})
			},
			
			
			//获取当前 时间 区段 类型 1 12点到6点  2 6点到晚上10点半   3 晚上10点半到晚上12点
			getNewTime() {
				let myDate = new Date();
				let hours = myDate.getHours()
				let minutes = myDate.getMinutes(); //获取当前分钟数(0-59)
				let timeType = 0 //判断 时间 区  1 凌晨飞营业时间  2 营业时间   3 晚上的非营业时间    
			
				let storehouseStartTime = parseInt(this.storehouse.start_time) //仓库开始营业时间
				let storehouseEndTime = parseInt(this.storehouse.end_time) //仓库结束营业时间
			
				if (hours < storehouseStartTime) {
					timeType = 1
					// } else if (hours < storehouseEndTime-2 || (hours == storehouseEndTime-2 && minutes < 30)) {
				} else if (hours < storehouseEndTime - 1) {
					timeType = 2
				} else {
					timeType = 3
				}
				this.timeType = timeType
				return {
					myDate,
					hours,
					minutes,
					timeType
				}
			},
			// 格式化 日期
			getDate(myDate, yd) {
				let year = myDate.getFullYear()
				let month = (myDate.getMonth() + 1) > 9 ? (myDate.getMonth() + 1) : '0' + (myDate.getMonth() + 1)
				let day = myDate.getDate() > 9 ? myDate.getDate() : '0' + myDate.getDate()
				if (yd) {
					return month + '/' + day
				} else {
					return year + '/' + month + '/' + day
				}
			},
			//选择 左边 日期
			seleLeft(index) {
				this.leftData.index = index
				this.listData.data = this.rightData[index]
				this.listData.index = -2
				this.bgData[this.bgDataIndex].leftIndex = index
				this.bgData[this.bgDataIndex].rightIndex = -2
			},
			//选择右边时间
			seleRight(index) {
				this.listData.index = index
				this.bgData[this.bgDataIndex].rightIndex = index
				if (index == -1) {
					this.bgData[this.bgDataIndex].label = this.i18n('102')
					// this.bgData[this.bgDataIndex].delivery_time = this.leftData.data[0].value + ' ' + this.listData.data[0]
					// 	.value
					this.bgData[this.bgDataIndex].delivery_time = 10001
				} else {
					this.bgData[this.bgDataIndex].delivery_time = this.leftData.data[this.leftData.index].value + ' ' +
						this.listData.data[this.listData.index].value
					this.bgData[this.bgDataIndex].label = this.leftData.data[this.leftData.index].label + ' ' + this
						.listData.data[this.listData.index].label
				}
			},
			bgDataIndexChange(index) {
				this.bgData.map((item) => {
					if (this.delivery_modeint == 1) {
						if (item.genre == 0) {
							item.leftIndex = this.leftData.index
							item.rightIndex = this.listData.index
						}
					} else {
						if (item.genre == 1) {
							item.leftIndex = this.leftData.index
							item.rightIndex = this.listData.index
						}
					}
				})
				this.bgDataIndex = index
				this.init()
			},
			open(genre){
				if(this.bgData.length==2){
					if (genre == 0) {
						this.bgDataIndexChange(1)
					}else{
						this.bgDataIndexChange(0)
					}
				}
				this.show=true
			},
			close(){
				this.show=false
			}
		},
		mounted() {
			let bgData = []
			if (this.ydData.length > 0) {
				bgData.push({
					isLj: false,
					label: false,
					genre: 1,
					leftIndex: 0,
					rightIndex: -2,
					delivery_time: 0,
					spData: this.ydData
				})
			}
			if (this.jsData.length > 0) {
				bgData.push({
					isLj: false,
					label: false,
					genre: 0,
					delivery_time: 0,
					leftIndex: 0,
					rightIndex: -2,
					spData: this.jsData
				})
			}
			
			this.bgData = bgData
			this.storehouse = this.$store.state.serviceArea.seleLoca.storehouses[0]
			this.init()
		},
	};
</script>

<style scoped>
	.but{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.but .qx{
		width: 160px;
		height: 44px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 24px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		line-height: 44px;
		margin-right: 24px;
		cursor: pointer;
	}
	.but .xt{
		height: 44px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 24px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		line-height: 44px;
		padding: 0 23px;
		margin-right: 24px;
		cursor: pointer;
	}
	.but .qd{
		width: 160px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		text-align: center;
		font-weight: 400;
		line-height: 44px;
		cursor: pointer;
	}
	.timeList{
		height: 180px;
		display: flex;
		padding-bottom: 17px;
	}
	.timeList .left{
		width: 100px;
		height: 100%;
		background: #F5F5F5;
		margin-right: 19px;
	}
	.timeList .left .item{
		width: 100px;
		height: 48px;
		background: #F5F5F5;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #4E5969;
		font-weight: 400;
		text-align: center;
		line-height: 48px;
		cursor: pointer;
	}
	.timeList .left .item span{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #1D2129;
		font-weight: 400;
	}
	.timeList .left .itemAc{
		background: #FFFFFF;
		color: #56AB7B;
		position: relative;
	}
	.timeList .left .itemAc::after{
		content: '';
		width: 3px;
		height: 48px;
		background: #56AB7B;
		border-radius: 0px 8px 8px 0px;
		position: absolute;
		left: 0;
		top: 0;
	}
	.timeList .left .itemAc span{
		color: #56AB7B;
	}
	.timeList .right{
		padding-top: 8px;
		flex: 1;
		height: 180px;
		box-sizing: border-box;
		overflow-y: scroll;
		display: flex;
		align-content: baseline;
		flex-wrap: wrap;
	}
	.timeList .right .item{
		background: rgba(86,171,123,0.10);
		width: 80px;
		height: 32px;
		border-radius: 4px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #000000;
		text-align: center;
		font-weight: 500;
		line-height: 32px;
		margin-right: 7px;
		margin-bottom: 16px;
		cursor: pointer;
	}
	.timeList .right .itemAc{
		background: #56AB7B;
		color: #fff;
	}
	.spList{
		width: 100%;
		overflow-x: scroll;
		display: flex;
		align-items: center;
		border-bottom: 0.5px solid rgba(0,0,0,.1);
	}
	.spList .item{
		margin-right: 16px;
		width: 58px;
	}
	.spList .item img{
		width: 58px;
		height: 59px;
		border-radius: 10px;
		display: block;
		margin-bottom: 4px;
	}
	.spList .item div{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #121212;
		line-height: 20px;
		font-weight: 400;
	}
	.bg{
		padding: 24px 0 16px 0;
		display: flex;
		align-items: center;
	}
	.bg div{
		width: 120px;
		height: 44px;
		background: rgba(86,171,123,0.10);
		border-radius: 22px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #272E3B;
		text-align: center;
		font-weight: 500;
		margin-right: 16px;
		line-height: 44px;
		cursor: pointer;
	}
	.bg div.ac{
		background: #56AB7B;
		color: #FFFFFF;
	}
	>>>.el-dialog__body {
		padding: 0 16px 25px 16px;
	}
	
	>>>.el-dialog {
		width: 600px;
		background: #FFFFFF;
		border-radius: 10px;
	}
	
	>>>.el-dialog__header {
		padding: 0;
	}
	
	>>>.el-dialog__header div {
		padding: 0 16px;
		height: 53px;
		display: flex;
		justify-content: space-between;
		border: 0.5px solid rgba(0, 0, 0, .1);
	}
	
	>>>.el-dialog__header .titleLeft {
		display: block;
		height: 53px;
		border: none;
		padding: 0;
	}
	
	>>>.el-dialog__header .titleLeft div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 53px;
		font-weight: 500;
		border: none;
		display: block;
		padding: 0;
		height: 53px;
	}
	
	>>>.el-dialog__header .titleRight {
		width: 53px;
		height: 53px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		padding: 0;
	}
	
	>>>.el-dialog__header .titleRight div {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		border: none;
		padding: 0;
	}
	
	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>