<template>
	<div class="details">
		<div class="title">
			{{i18n('34')}}
		</div>
		<div class="inner" v-if="info">
			<div class="one">
				<shxx :addressData="addressData" :info='info' :status="status" @cancel="cancel"
					@addressChange="addressChange" @timeChange="timeChange" style="margin-bottom: 20px;" @remarkChange="remarkChange"></shxx>
				<packa :data="ydData.length>0?ydData:jsData" :genre="info.order.delivery_modeint==2?1:0" :status="status"></packa>
			</div>
			<div class="two">
				<ddzy :orderData="info" :status="status"></ddzy>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		orderInfo,
		cancel,
		update,
		// raterider
	} from "@/api/order.js"

	import shxx from "./components/shxx.vue"
	import ddzy from "./components/ddzy.vue"
	import packa from "./components/packa.vue"
	export default {
		name: "orderDetails",
		components: {
			shxx,
			ddzy,
			packa
		},
		data() {
			return {
				order_id: 0,
				info: false,
				addressData: {
					id: 0
				}
			}
		},
		methods: {
			remarkChange(data){
				this.info.order.order_remarks = data
				this.update()
			},
			addressChange(data) {
				this.addressData = data
				this.update()
			},
			timeChange(data) {
				if (data[0].delivery_time == 10001) {
					this.info.order.hope_delivery_end_time = data[0].delivery_time
				} else {
					this.info.order.hope_delivery_end_time = new Date(data[0].delivery_time).getTime() / 1000
				}
				this.update()
			},
			//更新订单信息
			async update() {
				await update({
					order_id: Number(this.info.order.id),
					address_id: Number(this.addressData.id),
					delivery_time: Number(this.info.order.hope_delivery_end_time),
					order_remarks: this.info.order.order_remarks
				})
			},
			async orderInfo() {
				let {
					data
				} = await orderInfo({
					order_id: this.order_id
				})
				data.commodity.map(item => {
					item.Count = item.count
					item.base = {
						cover: item.image,
						zh_name: item.commodity_zh_name,
						en_name: item.commodity_en_name,
						vi_name: item.commodity_vi_name,
					}
					item.skuAc = {
						price: item.price,
						zh_name: item.sku_zh_name,
						en_name: item.sku_en_name,
						vi_name: item.sku_vi_name,
					}
				})
				this.info = data
				this.addressData = {
					id: data.order.address_id,
					city: data.order.receiver_city,
					name: data.order.receiver_name,
					phone: data.order.receiver_phone.substring(2),
					detail_address: data.order.receiver_detail_address
				}
			},
			async cancel() {
				await cancel({
					set_id: Number(this.info.set.id)
				})
				this.orderInfo(this.info.order.id)
			},
		},
		computed: {
			ydData() {
				let ydData = []
				if (this.info) {
					if (this.info.order.delivery_modeint == 2) {
						ydData = this.info.commodity
					}
				}
				return ydData
			},
			jsData() {
				let jsData = []
				if (this.info) {
					if (this.info.order.delivery_modeint != 2) {
						jsData = this.info.commodity
					}
				}
				return jsData
			},
			status() {
				let status = 0
				if (this.info) {
					let orderStatus = this.info.order.status
					if (orderStatus == 1) {
						status = 1 //待付款
					}
					if (orderStatus == 2 || orderStatus == 3 || orderStatus == 13 || orderStatus == 14) {
						status = 2 //待发货
					}
					if (orderStatus == 4 || orderStatus == 5) {
						status = 3 //待收货
					}
					if (orderStatus == 6 || orderStatus == 7) {
						status = 4 //已完成 可申请售后
					}
					if (orderStatus == 8 || orderStatus == 11 || orderStatus == 12) {
						status = 5 //售后中
					}
					if (orderStatus == 9) {
						status = 6 //订单取消
					}
					if (orderStatus == 10) {
						status = 7 //售后完成 不能再申请售后了
					}
				}
				return status
			}
		},
		created() {
			this.order_id = Number(this.$route.query.order_id)
			this.orderInfo()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.inner {
		flex: 1;
		display: flex;
		height: 100%;
	}

	.one {
		margin-right: 24px;
		width: 718px;
		height: 100%;
		overflow-y: scroll;
		padding-bottom: 80px;
		box-sizing: border-box;
	}

	.two {
		width: 290px;
		height: 100%;
		overflow-y: scroll;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}

	.details {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
