<template>
	<div class="spItem">
		<div class="zt">
			<img :src="data.base.cover" alt="" class="bg">
			<view class="sx" v-if="type==2">
				<img src="@/assets/bh.png">
			</view>
		</div>
		<div class="info">
			<div class="name over2">
				{{lang=='zh'?data.base.zh_name:lang=='en'?data.base.en_name:data.base.vi_name}}
			</div>
			<div class="gg">
				{{lang=='zh'?data.skuAc.zh_name:lang=='en'?data.skuAc.en_name:data.skuAc.vi_name}}
			</div>
		</div>
		<div class="dj">
			<span>₱</span>{{data.skuAc.price}}
		</div>
		<div class="jjcz">
			{{data.Count}}
		</div>
		<div class="xj">
			<span>₱</span>{{data.skuAc.price*data.Count}}
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		name: "spItem",
		components: {

		},
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		computed: {
			type() {
				if (this.data) {
					if (this.data.invalid == 0) {
						return 1
					} else {
						return 2
					}
				} else {
					return 1
				}
			},
			lang() {
				return this.$store.state.i18n.lang
			},
		},
		created() {

		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.jjcz {
		width: 130px;
		background: #FFFFFF;
		font-family: Helvetica;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		text-align: center;
	}
	.spItem {
		width: 670px;
		margin: 0 auto;
		padding: 10px 0;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		align-items: center;
	}

	.spItem .zt {
		position: relative;
		width: 72px;
		height: 72px;
		border-radius: 3.43px;
		margin-right: 20px;
	}

	.spItem .zt .bg {
		width: 100%;
		height: 100%;
		display: block;
	}

	.spItem .zt .sx {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		border-radius: 3.43px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.30);
	}

	.spItem .zt .sx img {
		width: 60.95px;
		height: 48px;
		display: block;
	}
	.spItem .info{
		width: 214px;
		overflow: hidden;
	}
	.spItem .info .name{
		height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.spItem .info .gg{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	.spItem .dj{
		width: 130px;
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 20px;
		font-weight: 400;
	}
	.spItem .xj{
		width: 130px;
		text-align: center;
		font-family: Helvetica;
		font-size: 18px;
		color: #F23725;
		line-height: 22px;
		font-weight: 400;
	}
	.spItem .xj span{
		font-size: 12px;
	}
</style>
