import fetch from '@/utils/fetch.js';

//订单消息列表
export function orderMessageList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/message/list",
		method: 'POST',
		data
	})
}
//订单消息列表已读
export function orderSetRead(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/message/set/read",
		method: 'POST',
		data
	})
}
//下单结果
export function orderResult(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/result",
		method: 'POST',
		data
	})
}
//订单详情
export function orderInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/info",
		method: 'POST',
		data
	})
}
//订单列表
export function orderList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/list",
		method: 'POST',
		data
	})
}
//订单取消
export function cancel(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/cancel",
		method: 'POST',
		data
	})
}
//订单修改
export function update(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/update",
		method: 'POST',
		data
	})
}
//申请售后
export function aftersale(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/apply/aftersale",
		method: 'POST',
		data
	})
}
//售后详情
export function aftersaleInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/aftersale/info",
		method: 'POST',
		data
	})
}
//评价骑手
export function raterider(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/raterider",
		method: 'POST',
		data
	})
}
//重新支付
export function payagain(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/order/payagain",
		method: 'POST',
		data
	})
}

//下单
export function submit(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/order/submit",
		method: 'POST',
		data
	})
}