<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('193')}}
			</div>
			<div class="titleRight" @click="close">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="list">
			<div class="item itemTextarea">
				<div class="label">
					{{i18n('177')}}：
				</div>
				<!-- <input type="text" v-model="userInfo.birthday" class="input"> -->
				<textarea name="" id="" cols="30" rows="10" class="input" v-model="remarks"></textarea>
			</div>
		</div>
		
		<div class="but">
			<div class="" @click="close">
				{{i18n('55')}}
			</div>
			<div class="" @click="edit">
				{{i18n('56')}}
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "remarksChange",
		data() {
			return {
				show: false,
				remarks:''
			}
		},
		methods: {
			async edit() {
				if(this.remarks!=''){
					this.$emit('change',this.remarks)
					this.close()
				}
			},
			async open(data) {
				this.remarks=data
				this.show = true
			},
			close() {
				this.show = false
			},
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.but{
		padding: 0 70px;
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.but div{
		cursor: pointer;
	}
	.but div:nth-child(1){
		width: 197px;
		height: 44px;
		border: 1px solid rgba(86,171,123,1);
		border-radius: 22.5px;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #56AB7B;
		font-weight: 500;
	}
	.but div:nth-child(2){
		width: 226px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
	}
	.labelList {
		display: flex;
		align-items: center;
	}

	.labelList .bq {
		height: 32px;
		border-radius: 4px;
		line-height: 32px;
		padding: 0 20px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		margin-right: 24px;
		background: rgba(86, 171, 123, 0.10);
		cursor: pointer;
	}

	.labelList .bqAc {
		background: #56AB7B;
		color: #fff;
	}
	.mr{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.mr img{
		width: 16px;
		height: 16px;
		display: block;
		margin-right: 6px;
	}
	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
	}

	.itemTextarea {
		width: 100%;
		align-items: baseline;
	}

	.item .label {
		width: 82px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	.item .input {
		width: 180px;
		height: 32px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 16px;
		padding: 0 16px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
	}

	.itemTextarea .input {
		flex: 1;
		height: 79px;
		padding: 5px 16px;
	}

	>>>.el-dialog__body {
		padding: 0 32px 40px 32px;
	}

	>>>.el-dialog {
		width: 662px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}

	>>>.el-input__icon {
		line-height: 30px;
	}

	>>>.el-input__inner {
		border: none;
		height: 30px;
	}

	>>>.el-input__inner:focus {
		border: none !important;
	}

	>>>.el-select .el-input.is-focus .el-input__inner {
		border: none !important;
	}
</style>
