<template>
	<div class="shxx">
		<div class="tit">
			<div class="">
				{{i18n('194')}}
			</div>
			<div class="" @click="cancel" v-if="status<3">
				{{i18n('195')}}
			</div>
		</div>
		<div class="inn">
			<div class="one item">
				<div class="left">
					<div class="top">
						<div class="txt1 txt">
							<span>{{i18n('65')}}：</span>{{addressData.name}}
						</div>
						<div class="txt2 txt">
							<span>{{i18n('47')}}：</span>{{addressData.phone}}
						</div>
					</div>
					<div class="bot txt">
						<span>{{i18n('196')}}：</span>{{addressData.city}}--{{addressData.detail_address}}
					</div>
				</div>
				<div class="right" @click="seleAddressOpen" v-if="status<3">
					{{i18n('197')}}
				</div>
			</div>
			<div class="two item">
				<div class="left">
					<div class="top">
						<div class="txt">
							<span>{{i18n('177')}}：</span>{{info.order.order_remarks || '----'}}
						</div>
					</div>
				</div>
				<div class="right" v-if="status<3" @click="remarksChangeOpen">
					{{i18n('198')}}
				</div>
			</div>
			<div class="two item">
				<div class="left">
					<div class="top">
						<div class="txt1 txt">
							<span>{{info.order.delivery_modeint==2?i18n('85'):i18n('100')}}：</span>{{i18n('288')}}{{info.commodity.length}}{{i18n('289')}}
						</div>
						<div class="txt2 txt">
							<span>{{i18n('99')}}：</span>{{info.order.hope_delivery_end_time*1000 | formatTime('YMDHMS')}}
						</div>
					</div>
				</div>
				<div class="right" v-if="status<3" @click="seleTimeOpen">
					{{i18n('199')}}
				</div>
			</div>
		</div>
		<seleAddress ref="seleAddress" @addressChange="addressChange"></seleAddress>
		<seleTime ref="seleTime" :ydData="ydData" :jsData="jsData" v-if="info" @timeChange="timeChange"></seleTime>
		<remarksChange ref="remarksChange" @change="remarkChangeSuccess"></remarksChange>
	</div>
</template>

<script>
	import seleAddress from "@/components/seleAddress/index.vue"
	import seleTime from "@/components/seleTime.vue"
	import remarksChange from "./remarksChange.vue"
	export default {
		props: ['addressData', 'info','status'],
		components:{
			seleAddress,
			seleTime,
			remarksChange
		},
		name: "shxx",
		data() {
			return {
				
			}
		},
		methods: {
			remarksChangeOpen(){
				this.$refs.remarksChange.open(this.info.order.order_remarks)
			},
			addressChange(data){
				this.$emit("addressChange",data)
			},
			//配送时间选择
			timeChange(data) {
				this.$emit("timeChange",data)
				// this.seleTimeData = data
				// this.freight()
			},
			remarkChangeSuccess(data){
				this.$emit("remarkChange",data)
			},
			cancel(){
				this.$emit("cancel")
			},
			seleAddressOpen(){
				this.$refs.seleAddress.open()
			},
			seleTimeOpen(){
				this.$refs.seleTime.open()
			}
		},
		computed: {
			ydData() {
				let ydData = []
				if (this.info) {
					if (this.info.order.delivery_modeint == 2) {
						ydData = this.info.commodity
					}
				}
				return ydData
			},
			jsData() {
				let jsData = []
				if (this.info) {
					if (this.info.order.delivery_modeint != 2) {
						jsData = this.info.commodity
					}
				}
				return jsData
			},
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.item {
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		justify-content: space-between;
	}

	.item:last-child {
		border-bottom: none;
	}

	.one {
		padding: 16px 0 12px 0;
	}

	.two {
		padding: 14px 0;
	}

	.item .right {
		font-family: PingFangSC-Medium;
		font-size: 12px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
		cursor: pointer;
	}

	.item .left .top {
		display: flex;
		align-items: center;
	}

	.item .left .top .txt1 {
		width: 215px;
	}

	.item .left .txt {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		width: 350px;
	}

	.item .left .txt span {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
		width: 87px;
		display: inline-block;
	}

	.one .left .bot {
		margin-top: 16px;
	}

	.two .left .bot {
		margin-top: 8px;
	}

	.inn {
		padding: 0 24px 0px 24px;
	}

	.shxx {
		width: 718px;
		background: #FFFFFF;
		border-radius: 12px;
		overflow: hidden;
	}

	.tit {
		width: 100%;
		height: 48px;
		background: #FFFFFF;
		border-bottom: 1px solid #56AB7B;
		padding: 0 24px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.tit div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
	}

	.tit div:nth-child(2) {
		height: 28px;
		border: 0.5px solid rgba(86, 171, 123, 1);
		border-radius: 20px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		line-height: 28px;
		padding: 0 14px;
		cursor: pointer;
	}
</style>
