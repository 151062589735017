<template>
	<div class="packa">
		<div class="tit">
			<div :class="genre==1?'left yd':'left js'">
				{{genre==1?i18n('85'):i18n('100')}}
			</div>
			<div class="right" v-if="status==1">{{i18n('186')}}</div>
			<div class="right" v-if="status==2">{{i18n('187')}}</div>
			<div class="right" v-if="status==3">{{i18n('188')}}</div>
			<div class="right" v-if="status==4">{{i18n('189')}}</div>
			<div class="right" v-if="status==5">{{i18n('190')}}</div>
			<div class="right" v-if="status==6">{{i18n('191')}}</div>
			<div class="right" v-if="status==7">{{i18n('192')}}</div>
		</div>
		<div class="tabHeader">
			<div class="">
				{{i18n('126')}}
			</div>
			<div class="">
				{{i18n('127')}}
			</div>
			<div class="">
				{{i18n('78')}}
			</div>
			<div class="">
				{{i18n('128')}}
			</div>
		</div>
		<div :class="isZk?'spListInner':'spListInner spListInnerAc'">
			<spItem v-for="(item,index) in data" :key="index" :data='item'></spItem>
			<div class="zdTxt" v-if="data.length>5" @click="isZk=!isZk">
				{{i18n('130')}}{{data.length-5}}{{i18n('131')}}
				<img src="@/assets/view-list.png" alt="" v-if="isZk">
				<img src="@/assets/view-list-up.png" alt="" v-else>
			</div>
		</div>
	</div>
</template>

<script>
	import spItem from './spItem.vue'
	export default {
		props:['data','genre','status'],
		components:{
			spItem
		},
		data() {
			return {
				isZk:true,
			}
		},
		methods: {
			
		}
	}
</script>

<style scoped>
	.zdTxt {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 10;
		background: #fff;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		cursor: pointer;
	}
	
	.zdTxt img {
		width: 16px;
		height: 16px;
		opacity: 0.4;
		display: block;
	}
	
	.spListInner {
		width: 670px;
		margin: 0 auto;
		max-height: 516px;
		overflow: hidden;
		position: relative;
		padding-bottom: 50px;
		box-sizing: border-box;
	}
	
	.spListInnerAc {
		max-height: none;
	}
	.tabHeader{
		width: 670px;
		margin: 0 auto;
		height: 46px;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		align-items: center;
		
	}
	.tabHeader div{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
		width: 130px;
		text-align: center;
	}
	.tabHeader div:nth-child(1){
		padding-left: 93px;
		width: 214px;
		text-align: left;
	}
	.packa{
		width: 718px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 16px;
	}
	.tit{
		padding: 0 24px;
		border-bottom: 1px solid #56AB7B;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tit .left{
		height: 18px;
		border-radius: 4px;
		line-height: 18px;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		text-align: center;
		font-weight: 400;
		padding: 0 6px;
	}
	.tit .yd{
		border: 1px solid rgba(255,179,66,1);
		color: #FFB342;
	}
	.tit .js{
		border: 1px solid rgba(86,171,123,1);
		color: #56AB7B;
	}
	.tit .right{
		height: 28px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 20px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		line-height: 28px;
		padding: 0 21px;
	}
</style>